import React from "react";
import Image from "next/image";

type ImageProps = {
  src: string;
  alt: string;
  className: string | undefined;
};

const ImageWrapper: React.FC<ImageProps> = ({ src, alt, className }) => {
  return (
    <Image
      className={className}
      alt={alt}
      src={src}
      fill
      style={{ objectFit: "contain" }}
    />
  );
};

export default ImageWrapper;
