import Heading1 from "@/lib/Layout/Wrappers/Heading1";
import Section from "@/lib/Layout/Wrappers/Section";
import React, { DetailedReactHTMLElement, useEffect, useState } from "react";
import ImageWrapper from "@/lib/Layout/Wrappers/Image";
import axios from "axios";
import styles from "styles/styles.json"
import { Button } from "@chakra-ui/react";
import router from "next/router";

interface Author {
  author: string;
}

interface BlogDetails {
  id: any;
  excerpt?: string;
  attachment_image?: any;
  meta?: any;
  categories?: any;
  title: DetailedReactHTMLElement<{ dangerouslySetInnerHTML: { __html: any; }; }, HTMLElement>;
  description: DetailedReactHTMLElement<{ dangerouslySetInnerHTML: { __html: any; }; }, HTMLElement>;
  url: string;
  date: string;
  author: string;
  category: string;
}

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const renderHTML = (rawHTML: any) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  useEffect(() => {
    axios
      .get("https://blog.bonatra.com/wp-json/rae/v1/posts?page_no=1")
      .then(({ data: response }) => {
        if (response.status === 200) {
          setBlogs(response.posts_data.slice(0, 3));
        }
      })
      .catch((e) => {
      });
  }, []);

  return (

    <><h6 className="text-center text-6xl font-semibold">From the blog</h6><h4 className="py-3 text-lg text-center">Learn how to value your health</h4><ul className="flex justify-between md:justify-center items-center overflow-x-scroll snap-mandatory snap-x scrollbar-hide py-8">
      {blogs?.map((item: BlogDetails, id: number) => {
        return (
          <li key={id} className="flex snap-center">
            <BlogCard
              key={id}
              id={item?.id}
              title={renderHTML(item?.title)}
              description={renderHTML(item?.excerpt)}
              url={item?.attachment_image.img_srcset}
              date={item?.date}
              author={item?.meta?.author_name}
              category={item?.categories[0]?.name} />
          </li>
        );
      })}
    </ul><div className="flex">
        <Button
          m="auto"
          bg="black"
          fontSize={"xl"}
          color="white"
          _hover={{ bg: "gray.700" }}
          p={8}
          onClick={() => router.push({
            pathname: "https://blog.bonatra.com/",
          })}
        >
          Read More
        </Button>
      </div></>
  );
}

export default Blogs;

export const BlogCard = ({
  title,
  description,
  url,
  date,
  author,
  category,
  id
}: BlogDetails) => {
  return (
    <a href={`https://blog.bonatra.com/?p=${id}`} target="_blank">
      <div className="w-80 md:w-96 p-4 shadow-md ml-8 mb-8 h-[500px] md:h-[700px] rounded-xl flex flex-col justify-between">
        <div className="max-w-md h-64 relative rounded-lg">
          <ImageWrapper src={url} alt="blog-image" className="" />
        </div>
        <div className={`${styles.flexStart} items-center gap-3 py-4`}>
          <h5 className="text-xs text-gray-500">{date}</h5>
          <div className={`${styles.roundBorder} text-xs border-2 border-black bg-black rounded-full px-2 py-1 text-white`}>
            {category}
          </div>
        </div>
        <div className="text-base font-semibold py-3">{title}</div>
        <h3 className="text-sm md:text-base text-black text-opacity-95">{description}</h3>
        <Avatar author={author} />
      </div>
    </a>
  );
};

export const Avatar = ({ author }: Author) => {
  return (
    <div className={`${styles.flexStart} items-center gap-4 py-4`}>
      {/* <div className="bg-gray-200 w-12 h-12 rounded-full relative"/> */}
      <h2 className="text-sm font-bold">{author}</h2>
    </div>
  );
};
